import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@/assets/style/index.css"
import 'animate.css';

//import 'sweetalert2/src/sweetalert2.scss'

var mixin={
    computed:{
        getUserRolle(){
            return JSON.parse(localStorage.getItem('sh-userinfo')).user.role.name || false
            
        }
    }
}


// CommonJS

createApp(App).use(store).use(router).mixin(mixin).mount('#app')
