import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/LoginPage.vue'),
  },
  {
    path: '/layout',
    name: 'layoutPage',
    component: () => import('../views/layoutPage.vue'),
    children:[
      {
        path: '/map',
        name: 'MapPage',
        component: () => import('../views/MapPage.vue'),
      },
      {
        path: '/new',
        name: 'newMap',
        component: () => import('../views/newMap.vue'),
      },
      {
        path: '/users',
        name: 'UsersPage',
        component: () => import('../views/UsersPage.vue')
      },
      {
        path: '/houses',
        name: 'HousesPage',
        component: () => import('../views/HousesPage.vue')
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginPage.vue')
      }
    ]    
  },

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
